(function () {
  'use strict';

  /* ------------------------------------------------------------------

      01. Custom easings
      02. Preloader
      03. Header
      04. Page reveals
      05. Custom cursor
      06. Elements reveal
      07. Section sliders
      08. Isotope grids
      09. Lazy loading
      10. To top button
      11. Scroll down button
      12. Scroll to id

    -------------------------------------------------------------------*/

    // GSAP: turn off console warnings
    gsap.config({
      nullTargetWarn: false,
    });

    window.App = {};

    App.config = {
      headroom: {
        enabled: true,
        options: {
          classes: {
            initial: 'headroom',
            pinned: 'is-pinned',
            unpinned: 'is-unpinned',
            top: 'is-top',
            notTop: 'is-not-top',
            bottom: 'is-bottom',
            notBottom: 'is-not-bottom',
            frozen: 'is-frozen',
          },
        },
      },
      ajax: {
        enabled: true,
      },
      cursorFollower: {
        enabled: true,
        disableBreakpoint: '992', // cursor will be disabled on this device width
      },
    };

    App.html = document.querySelector('html');
    App.body = document.querySelector('body');
    App.SMcontroller = new ScrollMagic.Controller();

    if (App.config.headroom.enabled) {
      App.headroom = new Headroom(document.querySelector('.js-header'), App.config.headroom.options);
    }

    window.onload = function () {
      customEasingsInit();
      pageRevealEffects();
      Preloader.init();

      if (App.config.headroom.enabled) {
        App.headroom.init();
      }

      if (App.config.cursorFollower.enabled) {
        Cursor.init();
      }

      document.fonts.ready.then(() => {
        initComponents();
        initialReveal();
      });
    };

    // Reloads all scripts when navigating through pages
    function initComponents() {
      Header.init();
      lazyLoading();
      splitTextIntoLines();
      backButton();
      uiScrollDown();
      scrollToIdInit();

      sectionSlidersInit();

      feather.replace();
      videoBtn();
    }

    /* --------------------------------------------------
      01. Custom easings
    ---------------------------------------------------*/

    function customEasingsInit() {
      CustomEase.create('quart.out', '0.25, 1, 0.5, 1');
      CustomEase.create('quart.inOut', '0.76, 0, 0.24, 1');
    }

    /* --------------------------------------------------
      02. Preloader
    ---------------------------------------------------*/

    const Preloader = (function () {
      const preloader = document.querySelector('.js-preloader');
      const bg = preloader.querySelector('.preloader__bg');
      const progress = preloader.querySelector('.preloader__progress');
      const progressInner = preloader.querySelector('.preloader__progress__inner');

      function initial() {
        gsap.registerEffect({
          name: 'preloaderInitial',
          effect: (target, config) => {
            document.documentElement.classList.add('html-overflow-hidden');
            const tl = gsap.timeline();

            if (!document.body.classList.contains('preloader-visible')) {
              document.documentElement.classList.remove('html-overflow-hidden');
              return tl;
            }

            return tl
              .fromTo(progressInner, {
                // scaleY: 0,
              }, {
                scaleY: 1,
                ease: 'none',
                duration: 1,
                delay: 0.3,
                onStart: () => {
                  bg.classList.add('origin-top');
                },
              })
              .to(progress, {
                duration: 0.5,
                ease: 'quart.inOut',
                opacity: 0,
                scale: 0.75,
              }, '>-0.1')
              .to(bg, {
                ease: 'quart.inOut',
                duration: 0.6,
                scaleY: 0,
                onComplete: () => {
                  document.documentElement.classList.remove('html-overflow-hidden');
                  const anchorLink = new URL(location.href).hash;
                  if (anchorLink) {
                    document.getElementById(anchorLink.substring(1)).scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                },
              }, '>-0.5');
          },
          extendTimeline: true,
        });
      }

      function hide() {
        gsap.registerEffect({
          name: 'preloaderHide',
          effect: (target, config) => {
            const tl = gsap.timeline();

            return tl
              .to(progress, {
                delay: 0.15,
                duration: 0.5,
                ease: 'quart.inOut',
                opacity: 0,
                scale: 0.75,
                onStart: () => {
                  bg.classList.add('origin-top');
                },
              })
              .to(bg, {
                ease: 'quart.inOut',
                duration: 0.6,
                scaleY: 0,
                onComplete: () => {
                  document.documentElement.classList.remove('html-overflow-hidden');
                  document.documentElement.classList.remove('overflow-hidden');
                  document.body.classList.remove('overflow-hidden');
                },
              }, '>-0.5');
          },
          extendTimeline: true,
        });
      }

      function init() {
        if (!preloader) {
          return;
        }

        initial();
        // show();
        hide();
      }

      return {
        init,
      };
    }());

    /* --------------------------------------------------
      03. Header
    ---------------------------------------------------*/

    const Header = (function () {
      let navInner;
      let navBg;
      let navList;
      let navListLinks;
      let navInfoItems;

      let navBtnOpen;
      let navBtnClose;
      let navBack;

      let menuDeepLevel;
      const timeline = gsap.timeline();

      function updateVars() {
        navInner = document.querySelector('.js-nav-inner');
        navBg = navInner.querySelector('.js-nav-bg');
        navList = navInner.querySelector('.js-navList');
        navListLinks = navInner.querySelectorAll('.js-navList > li > a');
        navInfoItems = navInner.querySelectorAll('.js-navInfo-item');

        navBtnOpen = document.querySelector('.js-nav-open');
        navBtnClose = document.querySelector('.js-nav-close');
        navBack = document.querySelector('.js-nav-back');

        menuDeepLevel = 0;
      }

      function init() {
        updateVars();
        menuListBindEvents();
        menuAnimBindEvents();
        headerSticky();
      }

      function deepLevelCheck(level) {
        if (level) {
          gsap.to(navBack, {
            ease: 'quart.inOut',
            duration: 0.6,
            y: '0px',
            opacity: 1,
            onStart: () => {
              navBack.classList.remove('pointer-events-none');
            },
          });
        } else {
          gsap.to(navBack, {
            ease: 'quart.inOut',
            duration: 0.6,
            opacity: 0,
            onStart: () => {
              navBack.classList.add('pointer-events-none');
            },
          });
        }
      }

      function menuListBindEvents() {
        const listItems = document.querySelectorAll('.js-navList .menu-item-has-children');

        if (!listItems.length) {
          return;
        }

        navBack.addEventListener('click', () => {
          const visibleList = navList.querySelector('ul.is-visible');
          const parentList = visibleList.parentElement.parentElement;

          menuDeepLevel--;

          deepLevelCheck(menuDeepLevel);
          menuListStepAnimate(visibleList, parentList);
        });

        listItems.forEach((el) => {
          const parentLink = el.querySelector('li > a');
          parentLink.removeAttribute('href');

          parentLink.addEventListener('click', () => {
            const parent = el.parentElement;
            const subnavList = el.lastElementChild;

            menuDeepLevel++;

            deepLevelCheck(menuDeepLevel);
            menuListStepAnimate(parent, subnavList);
          });
        });
      }

      function menuListStepAnimate(hideList, showList) {
        const navBtnClose = document.querySelector('.js-nav-close');

        let hideListItems = hideList.children;
        hideListItems = Array.from(hideListItems);
        const hideListLinks = hideListItems.map((item) => item.querySelector('li > a'));

        let showListItems = showList.children;
        showListItems = Array.from(showListItems);
        const showListLinks = showListItems.map((item) => item.querySelector('li > a'));

        timeline
          .clear()
          .to(hideListLinks, {
            ease: 'quart.out',
            stagger: -0.04,
            duration: 1.0,
            y: '100%',
            onStart: () => {
              showList.classList.add('is-visible');
              hideList.classList.remove('is-visible');
              navBtnClose.classList.add('pointer-events-none');
            },
          })
          .to(showListLinks, {
            ease: 'quart.out',
            stagger: 0.08,
            duration: 1.2,
            y: '0%',
            onComplete: () => {
              navBtnClose.classList.remove('pointer-events-none');
            },
          }, '>-0.6');
      }

      function menuAnimBindEvents() {
        if (!navBtnOpen) {
          return;
        }

        navBtnOpen.addEventListener('click', () => {
          if (App.config.headroom.enabled) {
            App.headroom.freeze();
          }

          showMenu();
        });

        navBtnClose.addEventListener('click', () => {
          if (App.config.headroom.enabled) {
            App.headroom.unfreeze();
          }

          hideMenu();
        });
      }

      function showMenu() {
        navInner.classList.add('is-active');

        gsap.timeline()
          .set(navListLinks, {opacity: 1})
          .set(navBack, {opacity: 0})
          .fromTo(navBg, {
            scaleY: 0,
          }, {
            scaleY: 1,
            duration: 0.8,
            ease: 'quart.inOut',
            onComplete: () => {
              document.body.style.overflowY = 'hidden';
            },
          })
          .fromTo(navBtnClose, {
            y: '16px',
            opacity: 0,
          }, {
            ease: 'quart.out',
            duration: 0.8,
            y: '0px',
            opacity: 1,
          }, '>-0.2')
          .fromTo(navListLinks, {
            y: '100%',
          }, {
            ease: 'quart.out',
            stagger: 0.08,
            duration: 1.2,
            y: '0%',
          }, '>-0.7')
          .fromTo(navInfoItems, {
            opacity: 0,
            y: '34px',
          }, {
            ease: 'quart.out',
            stagger: 0.08,
            duration: 1.0,
            opacity: 1,
            y: '0px',
            onComplete: () => {
              navList.classList.add('is-visible');
              navBtnClose.classList.remove('pointer-events-none');
            },
          }, '<');
      }

      function hideMenu() {
        const navVisibleList = navInner.querySelector('.is-visible');
        const navActiveListLinks = navInner.querySelectorAll('.is-visible > li > a');
        menuDeepLevel = 0;

        gsap.timeline()
          .to([navBtnClose, navBack], {
            ease: 'quart.out',
            duration: 0.6,
            opacity: 0,
            y: '-16px',
            onStart: () => {
              navBtnClose.classList.add('pointer-events-none');
              navVisibleList.classList.remove('is-visible');
              navBg.classList.add('origin-top');
            },
          })
          .to(navActiveListLinks, {
            ease: 'quart.out',
            duration: 0.8,
            y: '-100%',
          }, '>-0.4')
          .to(navInfoItems, {
            opacity: 0,
            y: '-34px',
            ease: 'quart.out',
            duration: 0.8,
          }, '<')

          .to(navBg, {
            ease: 'quart.inOut',
            duration: 0.8,
            scaleY: 0,
            onStart: () => {
              document.body.style.overflowY = 'visible';
            },
            onComplete: () => {
              navBtnOpen.classList.remove('pointer-events-none');
              navBg.classList.remove('origin-top');
              navInner.classList.remove('is-active');
            },
          }, '>-0.6');
      }

      function headerSticky() {
        const header = document.querySelector('.js-header');

        if (!header) {
          return;
        }

        new ScrollMagic.Scene({
          offset: '2px',
        })
          .setClassToggle(header, 'is-sticky')
          .addTo(App.SMcontroller);
      }

      return {
        init,
      };
    }());

    /* --------------------------------------------------
      04. Page reveals
    ---------------------------------------------------*/

    function pageRevealEffects() {
      // masthead shapes
      gsap.registerEffect({
        name: 'mastheadShapes',
        effect: (target, config) => gsap.fromTo(target, {
          opacity: 0,
          y: config.y,
        }, {
          ease: config.easing,
          duration: config.duration,
          opacity: 1,
          y: '0%',
        }),
        extendTimeline: true,
        defaults: {
          easing: 'quart.out',
          duration: 3.0,
          y: '90%',
        },
      });

      // header, menu and ui elements
      gsap.registerEffect({
        name: 'uiElementsAnimate',
        effect: (target, config) => {
          let headerLogo;
          let headerMenu;
          let uiElements;

          if (document.querySelector('.js-header-logo')) {
            headerLogo = document.querySelector('.js-header-logo');
          }
          if (document.querySelector('.js-header-menu')) {
            headerMenu = document.querySelector('.js-header-menu');
          }
          if (document.querySelector('.js-ui')) {
            uiElements = document.querySelectorAll('.js-ui');
          }

          if (!headerLogo && !headerMenu && !uiElements) {
            return;
          }

          return gsap.fromTo([
            headerLogo,
            headerMenu,
            uiElements,
          ], {
            y: config.y,
            opacity: 0,
          }, {
            ease: config.easing,
            duration: config.duration,
            y: '0px',
            opacity: 1,
          });
        },
        extendTimeline: true,
        defaults: {
          easing: 'quart.out',
          duration: 0.8,
          y: '30px',
        },
      });

      // masthead background
      gsap.registerEffect({
        name: 'mastheadBackground',
        effect: (target, config) => gsap.fromTo(target, {
          scale: 1.4,
          opacity: 0,
        }, {
          ease: 'quart.inOut',
          duration: 1.4,
          scale: 1,
          opacity: 1,
        }),
        extendTimeline: true,
      });
    }

    const PageReveal = (function () {
      function mastheadType_1(tl) {
        if (!document.querySelector('.js-masthead-type-1')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-type-1');
        let title = false;
        const text = masthead.querySelector('.js-text');
        const button = masthead.querySelector('.js-button');

        if (masthead.querySelector('.js-title')) {
          title = masthead.querySelectorAll('.js-title .split__line');
        }

        const splitTitle = {
          stagger: 0.1,
          duration: 1.2,
          ease: 'quart.out',
          y: '0%',
        };

        const textButton = {
          stagger: 0.1,
          duration: 1,
          ease: 'quart.out',
          y: '0%',
          opacity: 1,
        };

        gsap.set([text, button], {
          y: '35px',
          opacity: 0,
        });

        if (masthead.classList.contains('js-shapes')) {
          const shapes = masthead.querySelectorAll('.js-shape');

          tl
            .mastheadShapes(shapes, '>-0.7')
            .to(title, splitTitle, '>-2.3')
            .to([text, button], textButton, '>-0.8')
            .uiElementsAnimate(null, '>-0.8');
        }

        if (masthead.classList.contains('js-bg')) {
          const bgItem = masthead.querySelector('.js-bg-item');

          tl
            .mastheadBackground(bgItem, '>-0.0')
            .to(title, splitTitle, '>-0.5')
            .to([text, button], textButton, '>-0.8')
            .uiElementsAnimate(null, '>-0.8');
        }
      }

      function mastheadType_2(tl) {
        if (!document.querySelector('.js-masthead-type-2')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-type-2');
        const shapes = masthead.querySelectorAll('.js-shape');
        const bgItem = masthead.querySelector('.js-bg-item');
        const title = masthead.querySelector('.js-title');
        const text = masthead.querySelector('.js-text');
        const button = masthead.querySelector('.js-button');

        let delay = '>-0.1';

        if (shapes.length) {
          tl.mastheadShapes(shapes, '>-0.2');
          tl.uiElementsAnimate(null, '>-2.5');
          delay = '>-0.9';
        } else if (bgItem) {
          tl.mastheadBackground(bgItem, '>-0.8');
          tl.uiElementsAnimate(null, '>-0.1');
          delay = '>-0.1';
        }

        tl
          .fromTo(title.querySelectorAll('.split__line'), {
            y: '100%',
          }, {
            stagger: 0.12,
            duration: 1.4,
            ease: 'quart.out',
            y: '0%',
          }, delay)
          .fromTo(text.querySelectorAll('.split__line'), {
            y: '100%',
          }, {
            stagger: 0.08,
            duration: 1.2,
            ease: 'quart.out',
            y: '0%',
          }, '>-0.8')
          .fromTo(button, {
            y: '100%',
          }, {
            ease: 'quart.out',
            duration: 1.2,
            y: '0%',
          }, '>-0.8');
      }

      function mastheadType_3(tl) {
        if (!document.querySelector('.js-masthead-type-3')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-type-3');
        let subtitle = false;
        let title = false;
        let text = false;
        const button = masthead.querySelector('.js-button');

        if (masthead.querySelector('.js-subtitle')) {
          subtitle = masthead.querySelectorAll('.js-subtitle .split__line');
        }

        if (masthead.querySelector('.js-title')) {
          title = masthead.querySelectorAll('.js-title .split__line');
        }

        if (masthead.querySelector('.js-text')) {
          text = masthead.querySelectorAll('.js-text .split__line');
        }

        const splitLines = {
          stagger: 0.1,
          duration: 1.2,
          ease: 'quart.out',
          y: '0%',
        };

        const slideButton = {
          ease: 'quart.out',
          duration: 1.2,
          y: '0%',
        };

        gsap.set(button, {y: '100%'});

        if (masthead.classList.contains('js-shapes')) {
          const shapes = masthead.querySelectorAll('.js-shape');

          tl
            .mastheadShapes(shapes, '>-0.7')
            .to(subtitle, splitLines, '>-2.0')
            .to(title, splitLines, '>-0.9')
            .to(text, splitLines, '>-0.9')
            .to(button, slideButton, '>-0.9')
            .uiElementsAnimate(null, '>-0.9');
        }

        if (masthead.classList.contains('js-bg')) {
          const bgItem = masthead.querySelector('.js-bg-item');

          tl
            .mastheadBackground(bgItem, '>-0.0')
            .to(subtitle, splitLines, '>-0.5')
            .to(title, splitLines, '>-0.9')
            .to(text, splitLines, '>-0.9')
            .to(button, slideButton, '>-0.9')
            .uiElementsAnimate(null, '>-0.9');
        }
      }

      function mastheadType_4(tl) {
        if (!document.querySelector('.js-masthead-type-4')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-type-4');
        const image = masthead.querySelector('.js-image');
        const imageCover = masthead.querySelector('.js-image-cover');
        let subtitle = false;
        let title = false;
        let text = false;
        const button = masthead.querySelector('.js-button');

        if (masthead.querySelector('.js-subtitle')) {
          subtitle = masthead.querySelectorAll('.js-subtitle .split__line');
        }

        if (masthead.querySelector('.js-title')) {
          title = masthead.querySelectorAll('.js-title .split__line');
        }

        if (masthead.querySelector('.js-text')) {
          text = masthead.querySelectorAll('.js-text .split__line');
        }

        gsap.set(image, {scale: 2.2});
        gsap.set(button, {y: '101%'});

        tl
          .to(imageCover, {
            duration: 0.8,
            ease: 'quart.inOut',
            scaleX: 0,
          }, '>-0.1')
          .to(image, {
            duration: 0.8,
            ease: 'quart.inOut',
            scale: 1,
          }, '>-0.8')

          .to(subtitle, {
            stagger: 0.1,
            duration: 1.0,
            ease: 'quart.out',
            y: '0%',
          }, '>-0.3')
          .to(title, {
            stagger: 0.1,
            duration: 1.0,
            ease: 'quart.out',
            y: '0%',
          }, '>-0.8')
          .to(text, {
            stagger: 0.1,
            duration: 1.0,
            ease: 'quart.out',
            y: '0%',
          }, '>-0.8')
          .to(button, {
            ease: 'quart.out',
            duration: 1,
            y: '0%',
          }, '>-0.8')

          .uiElementsAnimate(null, '>-0.8');
      }

      function mastheadPortfolioWorkType_1(tl) {
        if (!document.querySelector('.js-masthead-type-work-1')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-type-work-1');
        const subtitle = masthead.querySelectorAll('.js-subtitle .split__line');
        const title = masthead.querySelectorAll('.js-title .split__line');
        const infoItems = masthead.querySelectorAll('.js-info-item .split__line');

        const splitBase = {
          stagger: 0.1,
          duration: 1,
          ease: 'quart.out',
          y: '0%',
        };

        const splitInfoItems = {
          stagger: 0.08,
          duration: 0.8,
          ease: 'quart.out',
          opacity: 1,
          y: '0px',
        };

        gsap.set(infoItems, {opacity: 0, y: '34px'});

        if (masthead.classList.contains('js-shapes')) {
          const shapes = masthead.querySelectorAll('.js-shape');
          const image = masthead.querySelector('.js-image');

          gsap.set(image, {opacity: 0, y: '34px'});

          tl
            .mastheadShapes(shapes, '>-0.8')
            .to(subtitle, splitBase, '>-2.3')
            .to(title, splitBase, '>-0.8')
            .to(infoItems, splitInfoItems, '>-0.8')
            .to(image, {
              duration: 1,
              ease: 'quart.out',
              opacity: 1,
              y: '0px',
            }, '>-0.5');
        }

        if (masthead.classList.contains('js-bg')) {
          const image = masthead.querySelector('.js-image');

          gsap.set(image, {opacity: 0, scale: 1.3});

          tl
            .to(image, {
              duration: 1.0,
              ease: 'quart.inOut',
              opacity: 1,
              scale: 1,
            }, '>-0.2')
            .uiElementsAnimate(null, '>-0.4')
            .to(subtitle, splitBase, '>-0.6')
            .to(title, splitBase, '>-0.8')
            .to(infoItems, splitInfoItems, '>-0.8');
        }
      }

      function mastheadBlogArticle(tl) {
        if (!document.querySelector('.js-masthead-blog-article')) {
          return tl;
        }

        const masthead = document.querySelector('.js-masthead-blog-article');
        const info = masthead.querySelector('.js-info');
        const title = masthead.querySelector('.js-title');

        tl
          .fromTo(info, {
            opacity: 0,
            y: '34px',
          }, {
            duration: 1,
            ease: 'quart.out',
            opacity: 1,
            y: '0px',
          }, '>-0.2')
          .to(title.querySelectorAll('.split__line'), {
            stagger: 0.1,
            duration: 1.0,
            ease: 'quart.out',
            y: '0%',
          }, '>-0.7');
      }

      function base(tl) {
        if (
          document.querySelector('.js-page-header') ||
          document.querySelector('.js-masthead-type-1') ||
          document.querySelector('.js-masthead-type-2') ||
          document.querySelector('.js-masthead-type-3') ||
          document.querySelector('.js-masthead-type-4') ||
          document.querySelector('.js-masthead-type-work-1') ||
          document.querySelector('.js-masthead-blog-article')
        ) {
          return tl;
        }

        tl.add(() => {
          RevealAnim.init();
        });
      }

      function init(tl) {
        if (document.querySelector('.page-reveal-off')) {
          return tl;
        }

        if (document.querySelector('.js-slider-full-page')) {
          App.html.classList.add('full-page-slider');
        } else {
          App.html.classList.remove('full-page-slider');
        }

        if (
          document.querySelector('.js-page-header') ||
          document.querySelector('.js-masthead-type-1') ||
          document.querySelector('.js-masthead-type-2') ||
          document.querySelector('.js-masthead-type-3') ||
          document.querySelector('.js-masthead-type-4') ||
          document.querySelector('.js-masthead-type-work-1') ||
          document.querySelector('.js-masthead-blog-article')
        ) {
          RevealAnim.init();
        }

        mastheadType_1(tl);
        mastheadType_2(tl);
        mastheadType_3(tl);
        mastheadType_4(tl);
        mastheadPortfolioWorkType_1(tl);
        mastheadBlogArticle(tl);
        base(tl);

        return tl;
      }

      return {
        init,
      };
    }());

    function initialReveal() {
      let tl = gsap.timeline();
      tl.preloaderInitial();
      tl = PageReveal.init(tl);
    }

    /* --------------------------------------------------
      05. Custom cursor
    ---------------------------------------------------*/

    const Cursor = (function () {
      const cursor = document.querySelector('.js-cursor');
      let label;
      let icon;

      let clientX;
      let clientY;
      let cursorWidth;
      let cursorHeight;
      let cursorTriggers;
      let state;

      function variables() {
        cursor.querySelector('.js-follower');
        label = cursor.querySelector('.js-label');
        icon = cursor.querySelector('.js-icon');

        clientX = -100;
        clientY = -100;
        cursorWidth = cursor.offsetWidth / 2;
        cursorHeight = cursor.offsetHeight / 2;
        state = false;
      }

      function init() {
        if (!cursor) {
          return;
        }

        variables();
        state = true;
        cursor.classList.add('is-enabled');

        document.addEventListener('mousedown', (e) => {
          cursor.classList.add('is-mouse-down');
        });

        document.addEventListener('mouseup', (e) => {
          cursor.classList.remove('is-mouse-down');
        });

        document.addEventListener('mousemove', (event) => {
          clientX = event.clientX;
          clientY = event.clientY;
        });

        const render = () => {
          cursor.style.transform = `translate(${clientX - cursorWidth}px, ${clientY - cursorHeight}px)`;
          requestAnimationFrame(render);
        };

        requestAnimationFrame(render);

        update();
        breakpoint();
      }

      function enterHandler({target}) {
        cursor.classList.add('is-active');

        if (target.getAttribute('data-cursor-label')) {
          App.body.classList.add('is-cursor-active');
          cursor.classList.add('has-label');
          label.innerHTML = target.getAttribute('data-cursor-label');
        }

        if (target.getAttribute('data-cursor-icon')) {
          App.body.classList.add('is-cursor-active');
          cursor.classList.add('has-icon');
          const iconAttr = target.getAttribute('data-cursor-icon');
          icon.innerHTML = feather.icons[iconAttr].toSvg();
        }
      }

      function leaveHandler() {
        App.body.classList.remove('is-cursor-active');
        cursor.classList.remove('is-active');
        cursor.classList.remove('has-label');
        cursor.classList.remove('has-icon');
        label.innerHTML = '';
        icon.innerHTML = '';
      }

      function update() {
        if (!cursor) {
          return;
        }

        cursorTriggers = document.querySelectorAll([
          'button',
          'a',
          'input',
          '[data-cursor]',
          '[data-cursor-label]',
          '[data-cursor-icon]',
          'textarea',
          'label',
          '.why-senla__radio-option',
          '.career-courses__tab h4',
          '.pagination__item',
          '.feedback__arrow_right',
          '.feedback__arrow_left',
        ]);

        cursorTriggers.forEach((el) => {
          el.addEventListener('mouseenter', enterHandler);
          el.addEventListener('mouseleave', leaveHandler);
        });
      }

      function clear() {
        if (!cursor) {
          return;
        }

        cursorTriggers.forEach((el) => {
          el.removeEventListener('mouseenter', enterHandler);
          el.removeEventListener('mouseleave', leaveHandler);
        });
      }

      function hide() {
        if (!cursor) {
          return;
        }
        cursor.classList.add('is-hidden');
      }

      function show() {
        if (!cursor) {
          return;
        }
        cursor.classList.remove('is-hidden');
      }

      function breakpoint() {
        if (!state) {
          return;
        }
        if (!App.config.cursorFollower.disableBreakpoint) {
          return;
        }

        const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

        if (width < App.config.cursorFollower.disableBreakpoint) {
          state = false;
          cursor.classList.remove('is-enabled');
          clear();
        } else {
          state = true;
          cursor.classList.add('is-enabled');
          update();
        }

        window.addEventListener('resize', () => {
          const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

          if (width < App.config.cursorFollower.disableBreakpoint) {
            state = false;
            cursor.classList.remove('is-enabled');
            clear();
          } else {
            state = true;
            cursor.classList.add('is-enabled');
            update();
          }
        });
      }

      return {
        init,
        leaveHandler,
        update,
        clear,
        hide,
        show,
      };
    }());

    /* --------------------------------------------------
      06. Elements reveal
    ---------------------------------------------------*/

    const RevealAnim = (function () {
      function single() {
        const animationTarget = document.querySelectorAll('[data-anim]');

        if (!animationTarget.length) {
          return;
        }

        for (let i = 0; i < animationTarget.length; i++) {
          const el = animationTarget[i];

          new ScrollMagic.Scene({
            offset: '160px',
            triggerElement: el,
            triggerHook: 'onEnter',
            reverse: false,
          })
            .on('enter', (event) => {
              animateElement(el);
            })
            .addTo(App.SMcontroller);
        }
      }

      function container() {
        const animationContainer = document.querySelectorAll('[data-anim-wrap]');

        if (!animationContainer.length) {
          return;
        }

        for (let i = 0; i < animationContainer.length; i++) {
          const el = animationContainer[i];

          new ScrollMagic.Scene({
            offset: '160px',
            triggerElement: el,
            triggerHook: 'onEnter',
            reverse: false,
          })
            .on('enter', (event) => {
              const animChilds = el.querySelectorAll('[data-anim-child]');
              el.classList.add('animated');
              animChilds.forEach((el) => animateElement(el));
            })
            .addTo(App.SMcontroller);
        }
      }

      function animateElement(target) {
        let attrVal;
        let animDelay;
        let attrDelayPart;

        if (target.getAttribute('data-anim')) {
          attrVal = target.getAttribute('data-anim');
        } else {
          attrVal = target.getAttribute('data-anim-child');
        }

        if (attrVal.includes('delay-')) {
          attrDelayPart = attrVal.split(' ').pop();
          animDelay = attrDelayPart.substr(attrDelayPart.indexOf('-') + 1) / 10;
        }

        if (attrVal.includes('counter')) {
          counter(target, animDelay);
        } else if (attrVal.includes('line-chart')) {
          lineChart(target, animDelay);
        } else if (attrVal.includes('split-lines')) {
          splitLines(target, animDelay);
        } else {
          target.classList.add('is-in-view');
        }
      }

      function lineChart(target, animDelay = 0) {
        const counterVal = target.getAttribute('data-percent');

        gsap.fromTo(target.querySelector('.js-bar'), {
          scaleX: 0,
        }, {
          delay: 0.45 + animDelay,
          duration: 1,
          ease: 'power3.inOut',
          scaleX: counterVal / 100,
        });

        const object = {count: 0};
        const barPercent = target.querySelector('.js-number');

        gsap.to(object, {
          count: counterVal,
          delay: 0.45 + animDelay,
          duration: 1,
          ease: 'power3.inOut',

          onUpdate() {
            barPercent.innerHTML = `${Math.round(object.count)}%`;
          },
        });
      }

      function counter(target, animDelay = 0) {
        const counterVal = target.getAttribute('data-counter');
        const counterAdd = target.getAttribute('data-counter-add');
        const totalDelay = animDelay;
        let symbols = '';

        const object = {count: 0};
        const counterNum = target.querySelector('.js-counter-num');

        const split = String(counterVal).split('.');
        let decimals = 0;

        if (split.length > 1) {
          decimals = split[1].length;
        }

        if (counterAdd) {
          symbols = counterAdd;
        }

        gsap.to(object, {
          count: counterVal,
          delay: totalDelay,
          duration: 1.8,
          ease: 'power3.inOut',

          onUpdate() {
            counterNum.innerHTML = object.count.toFixed(decimals) + symbols;
          },
        });
      }

      function splitLines(target, animDelay = 0) {
        const lines = target.querySelectorAll('.split__line');

        gsap.to(lines, {
          delay: animDelay,
          stagger: 0.08,
          duration: 0.85,
          ease: 'power2.out',
          y: '0%',
        });
      }

      function init() {
        single();
        container();
      }

      return {
        init,
      };
    }());

    function splitTextIntoLines() {
      let target;

      if (App.body.classList.contains('page-reveal-off')) {
        target = document.querySelectorAll('[data-split=\'lines\']:not([data-split-page-reveal])');
      } else {
        target = document.querySelectorAll('[data-split=\'lines\']');
      }

      if (!target.length) {
        return;
      }

      target.forEach((el) => {
        let content;
        const test = el.querySelectorAll('* > *:not(br):not(span)');

        if (test.length > 0) {
          content = el.querySelectorAll('* > *:not(br):not(span)');
        }

        new SplitText(content, {
          type: 'lines',
          linesClass: 'overflow-hidden',
        });

        content.forEach((el) => {
          const lines = el.querySelectorAll('.overflow-hidden');

          new SplitText(lines, {
            type: 'lines',
            linesClass: 'split__line',
          });
        });

        gsap.set(el.querySelectorAll('.split__line'), {
          y: '100%',
        });
      });
    }

    /* --------------------------------------------------
      08. Section sliders
    ---------------------------------------------------*/

    function sectionSlidersInit() {
      const sectionSlider = document.querySelectorAll('.js-section-slider');

      if (!sectionSlider.length) {
        return;
      }

      for (let i = 0; i < sectionSlider.length; i++) {
        const el = sectionSlider[i];

        let gap = 0;
        let loop = false;
        let centered = false;
        let pagination = false;
        let dinamicPagination = false;

        if (el.getAttribute('data-gap')) {
          gap = el.getAttribute('data-gap');
        }
        if (el.hasAttribute('data-loop')) {
          loop = true;
        }
        if (el.hasAttribute('data-center')) {
          centered = true;
        }
        if (el.hasAttribute('data-dynamic')) {
          dinamicPagination = true;
        }

        if (el.hasAttribute('data-pagination')) {
          pagination = {
            el: el.querySelector('.js-pagination'),
            bulletClass: 'pagination__item',
            bulletActiveClass: 'is-active',
            bulletElement: 'div',
            clickable: true,
            dynamicBullets: dinamicPagination,
          };
        }

        const colsArray = el.getAttribute('data-slider-col').split(' ');

        let cols_base = 1;
        let cols_lg = 1;
        let cols_md = 1;
        let cols_sm = 1;

        colsArray.forEach((el) => {
          if (el.includes('base')) {
            cols_base = el.slice(-1);
          }
          if (el.includes('lg')) {
            cols_lg = el.slice(-1);
          }
          if (el.includes('md')) {
            cols_md = el.slice(-1);
          }
          if (el.includes('sm')) {
            cols_sm = el.slice(-1);
          }
        });

        new Swiper(el, {
          speed: 800,
          autoHeight: true,
          spaceBetween: parseInt(gap),
          centeredSlides: centered,
          parallax: true,
          touchEventsTarget: 'wrapper',

          loop,

          lazy: {
            loadPrevNext: true,
          },

          slidesPerView: parseInt(cols_base),

          breakpoints: {
            1199: {slidesPerView: parseInt(cols_lg)},
            991: {slidesPerView: parseInt(cols_md)},
            767: {slidesPerView: parseInt(cols_sm)},
          },

          navigation: {
            prevEl: el.querySelector('.js-prev'),
            nextEl: el.querySelector('.js-next'),
          },

          pagination,
        });
      }
    }

    /* --------------------------------------------------
      11. Lazy loading
    ---------------------------------------------------*/

    function lazyLoading() {
      if (!document.querySelector('.js-lazy')) {
        return;
      }

      new LazyLoad({
        elements_selector: '.js-lazy',
      });
    }

    /* --------------------------------------------------
      13. To top button
    ---------------------------------------------------*/

    function backButton() {
      const button = document.querySelector('.js-backButton');

      if (!button) {
        return;
      }

      const scrollElement = window.document.documentElement;

      const duration = () => {
        if (scrollElement.scrollTop < 1600) {
          return 1;
        }
        return 2.2;
      };

      button.addEventListener('click', () => {
        gsap.to(scrollElement, {
          duration: duration(),
          ease: 'power2.inOut',
          scrollTo: 0,
        });
      });

      new ScrollMagic.Scene({
        offset: '400px',
      })
        .setClassToggle(button, 'is-visible')
        .addTo(App.SMcontroller);
    }

    /* --------------------------------------------------
      14. Scroll down button
    ---------------------------------------------------*/

    function uiScrollDown() {
      const target = document.querySelector('.js-ui-scroll-button');

      if (!target) {
        return;
      }

      const destination = document.querySelector('section:nth-of-type(2)');

      target.addEventListener('click', () => {
        gsap.to(window.document.documentElement, {
          duration: 1,
          ease: 'power2.inOut',
          scrollTo: destination.offsetTop,
        });
      });
    }

    /* --------------------------------------------------
      15. Video
    ---------------------------------------------------*/

    function videoBtn() {
      GLightbox({
        autoplayVideos: false,
        touchNavigation: false,
      });
    }

    /* --------------------------------------------------
      16. Scroll to id
    ---------------------------------------------------*/

    function scrollToIdInit() {
      const targets = document.querySelectorAll('.js-scroll-to-id');

      if (!targets.length) {
        return;
      }

      targets.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const id = el.getAttribute('href');
          const destination = document.querySelector(`#${id.slice(1)}`);

          gsap.to(window.document.documentElement, {
            duration: 1.2,
            ease: 'power2.inOut',
            scrollTo: destination.offsetTop,
          });
        });
      });
    }

  const cookiesAction = () => {
    const cookieBanner = document.querySelector('.cookies-banner');
    const settingsBanner = document.querySelector('.settings-banner');

    if (cookieBanner && settingsBanner) {
      const settingsButtons = cookieBanner.querySelectorAll('.open-settings-cookies');
      const closeSettingsButton = settingsBanner.querySelector('.settings-banner__closing-button');
      const switches = settingsBanner.querySelectorAll('.settings-banner__switch-label');
      const accordionButtons = settingsBanner.querySelectorAll('.settings-banner__open-accordion');
      const accordions = settingsBanner.querySelectorAll('.settings-banner__cookie-accordion');

      const acceptAllButtons = document.querySelectorAll('.accept-all-cookies');
      const acceptOnlyNecessary = cookieBanner.querySelector('.cookies-banner__only-necessary');
      const savePreferences = settingsBanner.querySelector('.settings-banner__save-preferences');

      const allProviders = settingsBanner.querySelectorAll('.settings-banner__cookie-provider');

      const cookies = {
        NecessaryCookies: 'NecessaryCookies',
        AnalyticsCookies: 'AnalyticsCookies',
        AdvertisingCookies: 'AdvertisingCookies',
        PerformanceCookies: 'PerformanceCookies',
        OtherCookies: 'OtherCookies',
      };

      const cookieStatuses = {
        NecessaryCookies: true,
        AnalyticsCookies: false,
        AdvertisingCookies: false,
        PerformanceCookies: false,
        OtherCookies: false,
      };

      const allCookiesAccept = () => {
        document.cookie = `${cookies.NecessaryCookies}=true`;
        document.cookie = `${cookies.AnalyticsCookies}=true`;
        document.cookie = `${cookies.AdvertisingCookies}=true`;
        document.cookie = `${cookies.PerformanceCookies}=true`;
        document.cookie = `${cookies.OtherCookies}=true`;
      };

      const toggleSettingsMenu = () => settingsBanner.classList.toggle('hidden');
      const toggleCookieBanner = () => cookieBanner.classList.toggle('hidden');
      const toggleBodyOverlay = () => document.body.classList.toggle('overlay');


      allProviders.forEach((provider) => {
        provider.innerHTML = window.location.host;
      });

      acceptAllButtons.forEach((button) => button.addEventListener('click', () => {
        settingsBanner.classList.add('hidden');
        cookieBanner.classList.add('hidden');
        allCookiesAccept();
        toggleBodyOverlay();
      }));

      acceptOnlyNecessary.addEventListener('click', () => {
        toggleCookieBanner();
        document.cookie = `${cookies.NecessaryCookies}=true`;
        toggleBodyOverlay();
      });

      savePreferences.addEventListener('click', () => {
        toggleSettingsMenu();
        toggleBodyOverlay();
        Object.keys(cookieStatuses).forEach((key) => {
          if (cookieStatuses[key]) {
            document.cookie = `${key}=true`;
          }
        });
      });

      settingsButtons.forEach((button) => {
        button.addEventListener('click', () => {
          toggleSettingsMenu();
          toggleCookieBanner();
        });
      });

      closeSettingsButton.addEventListener('click', () => {
        toggleSettingsMenu();
        toggleCookieBanner();
      });

      switches.forEach((switchElement) => {
        const switchToggle = switchElement.querySelector('.settings-banner__toggle-circle');

        if (switchElement.id === 'NecessaryCookies') {
          switchElement.classList.add('active');
          switchToggle.classList.add('active');
          switchElement.classList.add('disabled');
        }

        switchElement.addEventListener('click', () => {
          if (!switchElement.classList.contains('disabled') && !switchToggle.classList.contains('disabled')) {
            const switchId = switchElement.id;
            cookieStatuses[switchId] = !cookieStatuses[switchId];

            switchToggle.classList.toggle('active');
            switchElement.classList.toggle('active');
          }
        });
      });

      accordionButtons.forEach((button, index) => {
        button.addEventListener('click', () => {
          button.classList.toggle('active');
          accordions[index].classList.toggle('hidden');
        });
      });

      document.addEventListener('DOMContentLoaded', () => {
        toggleBodyOverlay();
      });
    }
  };

  function aboutAccordion() {
    const aboutSenla = document.querySelector('.about-senla');

    if (aboutSenla) {
      const accordionItems = document.querySelectorAll('.about-senla__item');

      accordionItems[0].classList.add('active');

      const resetActiveCards = () => {
        accordionItems.forEach((item) => {
          if (item.classList.contains('active')) {
            item.classList.remove('active');
          }
        });
      };

      accordionItems.forEach((item) => {
        item.addEventListener('click', () => {
          resetActiveCards();

          item.classList.toggle('active');
        });
      });
    }
  }

  function anchorScroll() {
    const anchors = document.querySelectorAll('a[href*="#"]');

    anchors.forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        const blockID = anchor.getAttribute('href').split('#');

        const currentElement = document.getElementById(blockID[1]);

        if (currentElement) {
          e.preventDefault();
          const elementBox = currentElement.getBoundingClientRect();
          const elementTop = elementBox.top + window.pageYOffset - 100;
          const elementTopSticky = elementBox.top + window.pageYOffset - 68;
          const isNotOnTop = document.querySelector('.is-not-top');
          if(isNotOnTop) {
            window.scrollTo({
              top: elementTopSticky,
              behavior: 'smooth',
            });
          } else {
            window.scrollTo({
              top: elementTop,
              behavior: 'smooth',
            });
          }
        }
      });
    });
  }

  function articleNav() {
    const blogNav = document.querySelector('.article-navigation');

    if (blogNav) {
      const mainNavLinks = document.querySelectorAll(
        '.article-navigation__nav-btn a',
      );
      mainNavLinks[0].parentElement.classList.add('active');

      const sections = document.querySelectorAll('.article-chapter');
      if (sections) {
        const bottomMargin = -80;
        const options = {
          root: null,
          threshold: 0.01,
          rootMargin: `0px 0px ${bottomMargin}% 0px`,
        };

        const observer = new IntersectionObserver(((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const sectionID = entry.target.getAttribute('id');

              for (const navLink of mainNavLinks) {
                const navLinkHref = navLink.getAttribute('href');
                const navLinkID = navLinkHref.split('#')[1];

                if (navLinkID === sectionID) {
                  navLink.parentElement.classList.add('active');
                } else {
                  navLink.parentElement.classList.remove('active');
                }
              }
            }
          });
        }), options);

        sections.forEach((section) => {
          observer.observe(section);
        });
      }
    }
  }

  function articleWhySenla() {
    const whySenla = document.querySelector('.article-why-senla');

    if (whySenla) {
      const itemsList = whySenla.querySelectorAll('.article-why-senla__item');
      const lastItemTitle = itemsList[itemsList.length - 1].querySelector('h3');

      if (lastItemTitle.offsetHeight > 84 && lastItemTitle.offsetHeight < 132) {
        lastItemTitle.classList.add('three-columns');
      } else if (lastItemTitle.offsetHeight >= 132) {
        lastItemTitle.classList.add('four-columns');
      }
    }
  }

  function articlesFilter() {
    // const filterWrapper = document.querySelector('.articles-filter');
    const filterWrapper = document.querySelector('.js-filter');

    if (filterWrapper) {
      const addEventsForSelect = function() {
        // const dropDown = document.querySelectorAll('.articles-filter__dropdown');
        const dropDown = document.querySelectorAll('.dropdown');

        if (dropDown) {
          dropDown.forEach((dropdownWrapper) => {
            const dropdownBtn = dropdownWrapper.querySelector('.dropdown__button');
            const dropdownList = dropdownWrapper.querySelector('.dropdown__list');
            const dropdownItems = dropdownList.querySelectorAll('.dropdown__list-item');

            // const dropdownBtn = dropdownWrapper.querySelector('.articles-filter__button');
            // const dropdownList = dropdownWrapper.querySelector('.articles-filter__list');
            // const dropdownItems = dropdownList.querySelectorAll('.articles-filter__item');
            // const resetBtn = document.querySelector('.articles-filter__reset-button');

            dropdownBtn.addEventListener('click', function() {
              // dropdownList.classList.toggle('visible');
              // this.classList.toggle('active');
              dropdownList.classList.toggle('dropdown__list_visible');
              this.classList.toggle('dropdown__button_active');
            });

            dropdownItems.forEach((listItem) => {
              listItem.addEventListener('click', function(e) {
                dropdownItems.forEach((el) => {
                  // el.classList.remove('active');
                  el.classList.remove('dropdown__list-item_active');
                });
                e.target.classList.add('dropdown__list-item_active');
                // e.target.classList.add('active');
                dropdownBtn.innerText = this.innerText;
                dropdownList.classList.remove('dropdown__list_visible');
                // dropdownList.classList.remove('visible');
              });
            });

            document.addEventListener('click', (e) => {
              if (e.target !== dropdownBtn) {
                dropdownBtn.classList.remove('dropdown__button_active');
                dropdownList.classList.remove('dropdown__list_visible');
              }
            });

            // resetBtn.addEventListener('click', () => {
            //   dropdownBtn.classList.remove('active');
            //   dropdownList.classList.remove('visible');
            // });
          });
        }
      };

      const mutationObserverFilter = new MutationObserver(addEventsForSelect);

      mutationObserverFilter.observe(filterWrapper, {
        attributes: false,
        childList: true,
      });

      addEventsForSelect();
    }
  }

  function backButtonOnFooter() {
    const backButton = document.querySelector('.js-backButton');

    window.addEventListener('scroll', () => {
      const {scrollHeight} = document.documentElement;
      const {clientHeight} = document.documentElement;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop + clientHeight > scrollHeight - 150) {
        backButton.classList.add('backButton-onFooter');
      } else if (backButton.classList.contains('backButton-onFooter')) {
        backButton.classList.remove('backButton-onFooter');
      }
    });
  }

  function careerFilter() {
    const careerVacancies = document.querySelector('.career-vacancies');

    if (careerVacancies) {
      const careerVacanciesColumns = document.querySelector('.career-vacancies__columns');

      const searchInputs = document.querySelectorAll('.vacancies-filter__search input');

      const setMobileFilter = function() {
        if (window.innerWidth < 1024) {
          const filter = document.querySelector('.career-vacancies__filter');
          const openFilterButton = document.querySelector('.career-vacancies__filter-open');
          const closeFilterButton = document.querySelector('.career-vacancies__close-filter');

          const openFilter = function() {
            filter.classList.add('active');
            document.querySelector('body').style.overflowY = 'hidden';
          };

          const closeFilter = function() {
            filter.classList.remove('active');
            document.querySelector('body').style.overflowY = 'scroll';
            closeFilterButton.removeEventListener('click', closeFilter);
            window.removeEventListener('keydown', closeFilter);
          };

          window.addEventListener('keydown', (evt) => {
            if (evt.key === 'Escape') {
              closeFilter();
            }
          });

          openFilterButton.addEventListener('click', () => {
            openFilter();
          });

          closeFilterButton.addEventListener('click', () => {
            closeFilter();
          });
        } else {
          document.querySelector('body').style.overflowY = 'scroll';
        }
      };

      const callback = function() {
        setMobileFilter();
      };

      const observer = new MutationObserver(callback);
      observer.observe(careerVacanciesColumns, {
        attributes: false,
        childList: true,
      });

      const filterOptions = function(input) {
        const searchContainer = input.closest('.vacancies-filter__search');
        const checkboxesList = searchContainer.nextElementSibling.querySelectorAll('.vacancies-filter__checkbox');
        const optionsList = searchContainer.nextElementSibling.querySelectorAll('label');
        const inputValue = input.value.toLowerCase();

        optionsList.forEach((option, index) => {
          const optionText = option.textContent.toLowerCase();

          if (optionText.indexOf(inputValue) > -1) {
            checkboxesList[index].style.display = '';
          } else {
            checkboxesList[index].style.display = 'none';
          }
        });
      };

      searchInputs.forEach((input) => {
        if (input) {
          input.addEventListener('input', (evt) => {
            filterOptions(evt.target);
          });
        }
      });

      window.addEventListener('load', () => {
        setMobileFilter();
      });

      window.addEventListener('resize', () => {
        setMobileFilter();
      });
    }
  }

  function notification() {
    const messageContainer = document.querySelector('.form-alert');
    const messageText = messageContainer.querySelector('p');
    const messageCloseButton = messageContainer.querySelector('button');

    function showErrorNotification(message, delay) {
      messageText.textContent = message;
      messageContainer.classList.add('active');
      messageContainer.classList.add('is-error');
      setTimeout(() => {
        closeNotification();
      }, delay);
    }
    function showSuccessNotification(message, delay, className = '') {
      messageContainer.classList.add('active');
      messageContainer.classList.add('is-success');
      className ? messageContainer.classList.add(className) : null;
      messageText.textContent = message;

      setTimeout(() => {
        closeNotification();
      }, delay);
    }

    function closeNotification() {
      messageContainer.className = 'form-alert';
      messageText.textContent = '';
    }

    messageCloseButton.addEventListener('click', closeNotification);

    return { showErrorNotification, showSuccessNotification };
  }

  function careerForm() {
    const careerForm = document.querySelector('.career-form');

    if (careerForm) {
      // file uploading
      const careerFormWrapper = document.querySelector('.career-form__wrapper');
      const body = document.querySelector('body');
      const uploadArea = document.querySelector('.career-form__file-input');
      const fileInput = document.querySelector('.career-form__file-input input');
      const fileButton = document.querySelector('.career-form__file-button');
      const fileButtonRemove = document.querySelector(
        '.career-form__file-button-remove'
      );

      const VALID_FILE_SIZE = 3000000; // 3mb
      const validFormatExtensions = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/pdf',
      ];

      let file;

      const toggleErrorMessage = (message) => {
        notification().showErrorNotification(message, 4000);
      };

      const showFile = function () {
        const fileInfo = document.querySelector('.career-form__file');
        const fileName = document.querySelector('.career-form__file-name');
        fileButton.style.display = 'none';
        fileInfo.classList.add('is-visible');
        fileName.textContent = file.name;

        fileButtonRemove.addEventListener('click', () => {
          fileInput.value = '';
          fileInfo.classList.remove('is-visible');
          fileButton.style.display = 'block';
        });
      };

      fileButton.addEventListener('click', () => {
        fileInput.click();
      });

      fileInput.addEventListener('change', function () {
        file = this.files[0];

        const fileType = file.type;
        const fileSize = file.size;

        if (fileSize > VALID_FILE_SIZE) {
          fileInput.value = '';
          toggleErrorMessage('The file is just too large');
          return;
        }

        if (!validFormatExtensions.includes(fileType)) {
          fileInput.value = '';
          toggleErrorMessage('Wrong file format');
          return;
        }
        showFile();
      });

      uploadArea.addEventListener('dragover', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      uploadArea.addEventListener('dragleave', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      uploadArea.addEventListener('drop', (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (event.dataTransfer.files.length > 1) {
          toggleErrorMessage('Only one file can be attached');
          return false;
        }

        file = event.dataTransfer.files[0];
        const fileType = file.type;
        const fileSize = file.size;

        if (fileSize > VALID_FILE_SIZE) {
          toggleErrorMessage('The file is too large');
          return false;
        }

        if (validFormatExtensions.includes(fileType)) {
          fileInput.files = event.dataTransfer.files;
        } else {
          toggleErrorMessage('Wrong file format');
          return false;
        }

        showFile();
      });

      // Textarea height

      const autoGrow = function (element) {
        element.style.height = `${element.scrollHeight}px`;
      };

      const textarea = document.querySelector(
        '.career-form textarea[name="about-yourself"]'
      );

      if (textarea) {
        const TEXTAREA_HEIGHT = 56;

        textarea.addEventListener('input', function () {
          if (textarea.value.length === 0) {
            textarea.style.height = `${TEXTAREA_HEIGHT}px`;
          } else {
            autoGrow(this);
          }
        });

        textarea.addEventListener('blur', function () {
          autoGrow(this);
        });
      }

      const privacyCheckboxLabel = document.querySelector(
        '.form__privacy-checkbox'
      );

      privacyCheckboxLabel.addEventListener('click', () => {
        privacyCheckboxLabel.classList.toggle('checked');
      });

      const form = document.querySelector('.career-form form');

      form.onsubmit = function () {
        autoGrow(textarea);
        if (!fileInput.value) {
          toggleErrorMessage('No file attached');

          return false;
        }

        if (!privacyCheckboxLabel.classList.contains('checked')) {
          toggleErrorMessage('Please check this box if you want to proceed.');

          return false;
        }
      };

      form.onreset = function () {
        const fileInfo = document.querySelector('.career-form__file');
        fileInput.value = '';
        fileInfo.classList.remove('is-visible');
        fileButton.style.display = 'block';
      };

      // Mobile placeholders

      const setMobilePlaceholders = function () {
        if (!careerForm.classList.contains('vacancies-description__form')) {
          const linkedinInput = document.querySelector(
            '.career-form input[name="linkedin"]'
          );
          const phoneInput = document.querySelector(
            '.career-form input[name="phone"]'
          );

          if (window.innerWidth < 460) {
            if (body.classList.contains('lang-ru')) {
              linkedinInput.placeholder = 'Linkedin';
            } else {
              linkedinInput.placeholder = 'Linkedin';
            }
          } else if (body.classList.contains('lang-ru')) {
            linkedinInput.placeholder = 'Ссылка на мессенджер';
          } else {
            linkedinInput.placeholder = 'Link to your Linkedin';
          }

          if (window.innerWidth < 420) {
            if (body.classList.contains('lang-ru')) {
              phoneInput.placeholder = 'Телефон';
            } else {
              phoneInput.placeholder = 'Phone';
            }
          } else if (body.classList.contains('lang-ru')) {
            phoneInput.placeholder = 'Номер телефона';
          } else {
            phoneInput.placeholder = 'Phone number';
          }
        }
      };

      // Disable Technologies section if Intern radio isn't checked
      const technologySection = document.querySelector('.career-form__field[data-name="technologies"]');
      const positionLevelOptions = document.querySelectorAll('input[name="position-level"]');
      positionLevelOptions.forEach((item, idx) => {
        item.addEventListener('click', (e) => {
          if(item.value === 'intern'){
            technologySection.classList.remove('hidden');
          } else {
            technologySection.classList.add('hidden');
            for (const checkbox of document.querySelectorAll('input[name="technologies[]"]')) {
              checkbox.checked = false;
            }
          }
        });
      });

      const recommendedVacancy = document.querySelector('input[name="recommended-vacancy"]');
      const recommenderSection = document.querySelector('.career-form__field[data-name="recommender"]');
      const recommenderNameField = recommenderSection.querySelector('textarea');
      recommendedVacancy.addEventListener('click', (e) => {
        if(recommendedVacancy.checked) {
          recommenderSection.classList.remove('hidden');
          recommenderNameField.required = true;
        } else {
          recommenderSection.classList.add('hidden');
          recommenderNameField.required = false;
          recommenderNameField.value = '';
        }
      });


      // Adding dynamic padding for mobile form
      const setMobileFormPadding = function () {
        if(careerForm.getAttribute('data-form') === 'career-form') {
          if (window.innerWidth < 768) {
            const confirmationSectionHeight = document.querySelector('.career-form__confirmation').offsetHeight;
            careerFormWrapper.style.cssText = `padding-bottom: ${ confirmationSectionHeight + 24 }px`;
          } else {
            careerFormWrapper.style.cssText = 'padding-bottom: 0';
          }
        }
      };

      window.addEventListener('load', () => {
        setMobilePlaceholders();
        setMobileFormPadding();
      });

      window.addEventListener('resize', () => {
        setMobilePlaceholders();
        setMobileFormPadding();
      });
    }
  }

  function caseBook() {
    const caseBook = document.querySelector('.case-book');

    if (caseBook) {
      // const mainColor = document
      //   .querySelector(".bb-custom-grid")
      //   .getAttribute("data-main-color");

      const bookPages = document.querySelector('.case-book__pages');
      const pagesList = bookPages.querySelectorAll('.case-book__page');
      const innerPagesList = document.querySelectorAll('.case-book__inner-pages');
      const cursor = document.querySelector('.js-cursor');
      const bookGrid = document.querySelector('.bb-custom-grid');
      const closeButtons = document.querySelectorAll('.case-book__close-btn');
      const nextSectionButtons = document.querySelectorAll(
        '.case-book__next-section-button'
      );
      const quoteBlocks = document.querySelectorAll('.case-book__content-quote');

      quoteBlocks.forEach((block) => {
        if (block.closest('.case-book__page--main-layout')) {
          block
            .closest('.case-book__page--main-layout')
            .classList.add('case-book__page--quote');
        }
      });

      const setImageWidth = () => {
        const diagramImages = document.querySelectorAll(
          '.case-book__content-image--diagram'
        );

        diagramImages.forEach((diagramImage) => {
          const image = diagramImage.querySelector('img');

          if (image.naturalWidth < image.naturalHeight) {
            diagramImage.classList.add('case-book__content-image--diagram-alt');
          }

          image.addEventListener('load', () => {
            if (image.naturalWidth < image.naturalHeight) {
              diagramImage.classList.add('case-book__content-image--diagram-alt');
            }
          });
        });
      };

      const setPageCounter = () => {
        let currentElem, lastElem;

        pagesList.forEach((page, idx) => {
          currentElem = page.querySelector(
            '.case-book__page-counter span:first-child'
          );
          lastElem = page.querySelector(
            '.case-book__page-counter span:last-child'
          );

          currentElem.textContent = idx + 1;
          lastElem.textContent = pagesList.length;

          const lastSlideCounterCurrent = document.querySelector(
            '.case-book__pages .case-book__page-counter--desktop span:first-child'
          );
          const lastSlideCounterLast = document.querySelector(
            '.case-book__pages .case-book__page-counter--desktop span:last-child'
          );

          lastSlideCounterCurrent.textContent = idx + 1;
          lastSlideCounterLast.textContent = pagesList.length;
        });

        // innerPagesList.forEach((innerPage) => {
        //   const slideList = innerPage.querySelectorAll(".case-book__page");

        //   slideList.forEach((slide, idx) => {
        //     currentElem = slide.querySelector(
        //       ".case-book__page-counter--desktop span:first-child"
        //     );
        //     lastElem = slide.querySelector(
        //       ".case-book__page-counter--desktop span:last-child"
        //     );
        //     currentMobileElem = slide.querySelector(
        //       ".case-book__page-counter--mobile span:first-child"
        //     );
        //     lastMobileElem = slide.querySelector(
        //       ".case-book__page-counter--mobile span:last-child"
        //     );

        //     currentElem.textContent = idx + 1;
        //     currentMobileElem.textContent = idx + 1;
        //     lastElem.textContent = slideList.length;
        //     lastMobileElem.textContent = slideList.length;
        //   });
        // });
      };

      const setControlButtons = () => {
        const topBookPages = bookPages.querySelectorAll('.case-book__page');
        const firstPageCursor = topBookPages[0].querySelector(
          '.case-book__side--first'
        );
        const lastPageCursor = topBookPages[
          topBookPages.length - 1
        ].querySelector('.case-book__side--second');

        firstPageCursor.style.cursor = 'default';
        lastPageCursor.style.cursor = 'default';

        innerPagesList.forEach((innerPage) => {
          const innerPages = innerPage.querySelectorAll('.case-book__page');

          if (innerPages.length === 1) {
            const firstButton = innerPage.querySelector(
              '.case-book__control--prev'
            );
            const lastButton = innerPage.querySelector(
              '.case-book__control--next'
            );
            const firstCursorArea = innerPage.querySelector(
              '.case-book__side--first'
            );
            const secondCursorArea = innerPage.querySelector(
              '.case-book__side--second'
            );

            firstButton.style.display = 'none';
            lastButton.style.display = 'none';
            firstCursorArea.style.cursor = 'default';
            secondCursorArea.style.cursor = 'default';
          } else if (innerPages.length > 1) {
            const firstPageButton = innerPages[0].querySelector(
              '.case-book__control--prev'
            );
            const lastPageButton = innerPages[
              innerPages.length - 1
            ].querySelector('.case-book__control--next');
            const firstPageCursorArea = innerPages[0].querySelector(
              '.case-book__side--first'
            );
            const secondPageCursorArea = innerPages[
              innerPages.length - 1
            ].querySelector('.case-book__side--second');

            firstPageButton.style.display = 'none';
            lastPageButton.style.display = 'none';
            firstPageCursorArea.style.cursor = 'default';
            secondPageCursorArea.style.cursor = 'default';
          }

          if (innerPages.length > 1) {
            const lastSectionButton = innerPages[
              innerPages.length - 1
            ].querySelector('.case-book__next-section-button--desktop');

            lastSectionButton.style.display = 'block';

            if (
              window.innerWidth <= 1050 ||
              (window.innerWidth > 800 && window.innerHeight < 700)
            ) {
              const lastSectionButtonMobile = innerPages[
                innerPages.length - 1
              ].querySelector('.case-book__next-section-button--mobile');

              lastSectionButtonMobile.style.visibility = 'visible';
            }
          } else if (innerPages.length === 1) {
            const firstSectionButton = innerPage.querySelector(
              '.case-book__next-section-button--desktop'
            );

            firstSectionButton.style.display = 'block';

            if (
              window.innerWidth <= 1050 ||
              (window.innerWidth > 800 && window.innerHeight < 700)
            ) {
              const firstSectionButtonMobile = innerPage.querySelector(
                '.case-book__next-section-button--mobile'
              );

              firstSectionButtonMobile.style.visibility = 'visible';
            }
          }
        });
      };

      // const setBookColors = () => {
      //   const WHITE_COLOR = "#ffffff";
      //   const secondColor = document
      //     .querySelector(".case-book__inner-books")
      //     .getAttribute("data-add-color");

      //   const pageControls = document.querySelectorAll(".case-book__control");
      //   const pageArrows = document.querySelectorAll(
      //     ".case-book__control svg path"
      //   );
      //   const innerControls = document.querySelectorAll(
      //     ".case-book__inner-books .case-book__control"
      //   );
      //   const innerArrows = document.querySelectorAll(
      //     ".case-book__inner-books .case-book__control svg path"
      //   );
      //   const mainColorContent = document.querySelectorAll(
      //     ".case-book__page .case-book__page--main-color .case-book__content-info"
      //   );
      //   const mainColorWrapper = document.querySelectorAll(
      //     ".case-book__page .case-book__page--main-color .case-book__page-content"
      //   );
      //   const secondColorContent = document.querySelectorAll(
      //     ".case-book__page .case-book__page--second-color .case-book__content-info"
      //   );
      //   const secondColorWrapper = document.querySelectorAll(
      //     ".case-book__page .case-book__page--second-color .case-book__page-content"
      //   );
      //   const twoColorsContent = document.querySelectorAll(
      //     ".case-book__page .case-book__page--two-colors .case-book__content-list"
      //   );
      //   const twoColorsWrapper = document.querySelectorAll(
      //     ".case-book__page .case-book__page--two-colors .case-book__page-content"
      //   );
      //   const twoColorsAltContent = document.querySelectorAll(
      //     ".case-book__page .case-book__page--two-colors-reverse .case-book__content-title"
      //   );
      //   const twoColorsAltWrapper = document.querySelectorAll(
      //     ".case-book__page .case-book__page--two-colors-reverse .case-book__page-content"
      //   );
      //   const quotes = document.querySelectorAll(
      //     ".case-book__page .case-book__content-quote blockquote span"
      //   );
      //   // const mainTitles = document.querySelectorAll(
      //   //   ".case-book__middle-page h2"
      //   // );
      //   const lastPageCenterer = document.querySelector(
      //     ".case-book__last-page .case-book__page-centerer"
      //   );
      //   const lastPageContent = document.querySelector(
      //     ".case-book__last-page .case-book__page-content"
      //   );
      //   const lastSlideControl = document.querySelector(
      //     ".case-book__last-page .case-book__control"
      //   );
      //   const secondColorListItems = document.querySelectorAll(
      //     ".case-book__lists-item"
      //   );

      //   secondColorListItems.forEach((item) => {
      //     const itemTitle = item.querySelector("h3");
      //     const itemIcons = item.querySelectorAll("svg");

      //     itemTitle.style.backgroundColor = mainColor;

      //     itemIcons.forEach((icon) => {
      //       icon.style.fill = mainColor;
      //     });
      //   });

      //   pageControls.forEach((control) => {
      //     control.style.backgroundColor = mainColor;
      //   });

      //   pageArrows.forEach((arrow) => {
      //     arrow.style.fill = WHITE_COLOR;
      //   });

      //   // mainTitles.forEach((title) => {
      //   //   title.style.color = mainColor;
      //   // });

      //   mainColorContent.forEach((mainContent) => {
      //     mainContent.style.backgroundColor = mainColor;
      //   });

      //   mainColorWrapper.forEach((mainWrapper) => {
      //     mainWrapper.style.backgroundColor = mainColor;
      //   });

      //   secondColorContent.forEach((secondContent) => {
      //     secondContent.style.backgroundColor = secondColor;
      //   });

      //   secondColorWrapper.forEach((secondWrapper) => {
      //     secondWrapper.style.backgroundColor = secondColor;
      //   });

      //   twoColorsContent.forEach((twoColorContent) => {
      //     twoColorContent.style.backgroundColor = mainColor;
      //   });

      //   twoColorsWrapper.forEach((wrapper) => {
      //     wrapper.style.backgroundColor = mainColor;
      //   });

      //   twoColorsAltContent.forEach((twoColorAltContent) => {
      //     twoColorAltContent.style.backgroundColor = mainColor;
      //   });

      //   twoColorsAltWrapper.forEach((twoColorAltWrapper) => {
      //     twoColorAltWrapper.style.backgroundColor = "#f3f8fa";
      //   });

      //   quotes.forEach((quote) => {
      //     quote.style.backgroundColor = mainColor;
      //   });

      //   lastPageCenterer.style.backgroundColor = mainColor;

      //   // lastSlideControl.querySelector("path").style.fill = mainColor;
      //   // lastSlideControl.style.backgroundColor = WHITE_COLOR;

      //   if (window.innerWidth <= 768) {
      //     lastPageCenterer.style.backgroundColor = "#2e2e2e";
      //     lastPageContent.style.backgroundColor = mainColor;

      //     // lastSlideControl.querySelector("path").style.fill = WHITE_COLOR;
      //     // lastSlideControl.style.backgroundColor = mainColor;

      //     innerControls.forEach((control) => {
      //       control.style.backgroundColor = WHITE_COLOR;
      //     });

      //     innerArrows.forEach((arrow) => {
      //       arrow.style.fill = mainColor;
      //     });
      //   }
      // };

      // const setMobileColors = () => {
      //   // const lastPageContent = document.querySelector(
      //   //   '.case-book__last-page .case-book__locations'
      //   // );
      //   // const lastSlideControl = document.querySelector(
      //   //   ".case-book__last-page .case-book__control"
      //   // );
      //   const partnersWrapper = document.querySelector(
      //     ".case-book__last-page .case-book__locations-background .case-book__page-wrapper"
      //   );

      //   if (window.innerWidth <= 768) {
      //     // lastPageContent.style.backgroundColor = "#2e2e2e";
      //     partnersWrapper.style.backgroundColor = mainColor;

      //     // lastSlideControl.querySelector("path").style.fill = "#ffffff";
      //     // lastSlideControl.style.backgroundColor = mainColor;
      //   } else {
      //     // lastPageContent.style.backgroundColor = mainColor;
      //     partnersWrapper.style.backgroundColor = "#2e2e2e";

      //     // lastSlideControl.querySelector("path").style.fill = mainColor;
      //     // lastSlideControl.style.backgroundColor = "#ffffff";
      //   }
      // };

      bookPages.addEventListener('click', (event) => {
        const isOpenBtn = event.target.classList.contains('case-book__open-btn');
        const isOpenIcon = event.target.closest('.case-book__open-btn');

        if (isOpenBtn || isOpenIcon) {
          const currentPage = event.target.closest('.case-book__page');

          pagesList.forEach((page, index) => {
            if (page === currentPage) {
              const innerBooksList = document.querySelectorAll(
                '.case-book__inner-pages'
              );

              innerBooksList[index].classList.add('active');
            }
          });
        }
      });

      nextSectionButtons.forEach((nextButton) => {
        nextButton.addEventListener('click', (event) => {
          const currentBook = event.target.closest('.case-book__inner-pages');
          currentBook.classList.remove('active');
        });
      });

      closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', (event) => {
          const currentBook = event.target.closest('.case-book__inner-pages');
          currentBook.classList.remove('active');
        });
      });

      bookGrid.addEventListener('mouseenter', () => {
        cursor.style.display = 'none';
      });

      bookGrid.addEventListener('mouseleave', () => {
        cursor.style.display = 'block';
      });

      window.addEventListener('resize', () => {
        // setMobileColors();
        // setBookColors();
        setControlButtons();
      });

      // Book settings
      const Page = (function () {
        const $grid = $('#bb-custom-grid');

        return {
          init() {
            $grid.find('div.bb-bookblock').each(function () {
              const $bookBlock = $(this);
              const $topBook = $grid.find('.case-book__pages');
              const $navNext = $bookBlock.find('.case-book__side--second');
              const $navPrev = $bookBlock.find('.case-book__side--first');
              const $buttonClose = $bookBlock.find('.case-book__close-btn');
              const $buttonNextSection = $bookBlock.find(
                '.case-book__next-section-button'
              );
              const $buttonNext = $bookBlock.find('.case-book__control--next');
              const $buttonPrev = $bookBlock.find('.case-book__control--prev');

              $bookBlock.bookblock({
                speed: 600,
                shadows: true,
                shadowSides: 0.8,
                shadowFlip: 0.7,
                circular: false,
                onEndFlip: function (page, isLimit) {
                  const contentBlocks = document.querySelectorAll(
                    '.case-book__page-content'
                  );

                  contentBlocks.forEach((block) => {
                    block.scrollTop = 0;
                  });
                },
              });

              $navNext.on('click', () => {
                $bookBlock.bookblock('next');
                return false;
              });

              $buttonNext.on('click', () => {
                $bookBlock.bookblock('next');
                return false;
              });

              $navPrev.on('click', () => {
                $bookBlock.bookblock('prev');
                return false;
              });

              $buttonPrev.on('click', () => {
                $bookBlock.bookblock('prev');
                return false;
              });

              $buttonClose.on('click', () => {
                $bookBlock.bookblock('first');
                return false;
              });

              $buttonNextSection.on('click', () => {
                $bookBlock.bookblock('first');
                $topBook.bookblock('next');
                return false;
              });
            });
          },
        };
      })();

      Page.init();
      setImageWidth();
      // setBookColors();
      // setMobileColors();
      setControlButtons();
      setPageCounter();
    }
  }

  function caseFilter() {
    const filterWrapper = document.querySelector('.js-filter');

    if (filterWrapper) {
      const addEventsForSelect = function() {
        const dropDown = document.querySelectorAll('.articles-filter__dropdown');

        if (dropDown) {
          dropDown.forEach((dropdownWrapper) => {
            const dropdownBtn = dropdownWrapper.querySelector('.articles-filter__button');
            const dropdownList = dropdownWrapper.querySelector('.articles-filter__list');
            const dropdownItems = dropdownList.querySelectorAll('.articles-filter__item');

            dropdownBtn.addEventListener('click', function() {
              dropdownList.classList.toggle('visible');
              this.classList.toggle('active');
            });

            dropdownItems.forEach((listItem) => {
              listItem.addEventListener('click', function(e) {
                dropdownItems.forEach((el) => {
                  el.classList.remove('active');
                });
                e.target.classList.add('active');
                dropdownBtn.innerText = this.innerText;
                dropdownList.classList.remove('visible');
              });
            });
          });
        }
      };

      const mutationObserverFilter = new MutationObserver(addEventsForSelect);

      mutationObserverFilter.observe(filterWrapper, {
        attributes: false,
        childList: true,
      });

      addEventsForSelect();
    }
  }

  function coursesAccordion() {
    const courses = document.querySelector('.career-courses');

    if (courses) {
      const tabsList = document.querySelector('.career-courses__tabs');
      const tabs = document.querySelectorAll('.career-courses__tab');

      tabs[0].classList.add('active');

      const removeActiveTabs = function() {
        tabs.forEach((tab) => {
          tab.classList.remove('active');
        });
      };

      const checkForActive = function(button) {
        const currentCard = button.closest('.career-courses__tab');
        const cardStatus = currentCard.classList.contains('active');

        if (!cardStatus) {
          removeActiveTabs();
          currentCard.classList.add('active');
        }
      };

      tabsList.addEventListener('click', (evt) => {
        const button = evt.target;

        if (button.classList.contains('career-courses__tab') ||
            button.closest('.career-courses__tab')) {
          checkForActive(button);
        }
      });
    }
  }

  function developmentProcess() {
    const developmentProcess = document.querySelector('.development-process');

    if (developmentProcess) {
      if (window.innerWidth > 1050) {
        const buttons = document.querySelectorAll('.development-process__button');

        const resetCards = (currentButton) => {
          const cards = document.querySelectorAll('.development-process__step');

          cards.forEach((card) => {
            card.classList.remove('active');
          });
        };

        buttons.forEach((button) => {
          button.addEventListener('click', (e) => {
            resetCards(e.target);

            const card = button.closest('.development-process__step');

            card.classList.toggle('active');
          });
        });
      }
    }
  }

  function expertiseTabs() {
    const expertise = document.querySelector('.company-expertise');

    if (expertise) {
      const filterButtons = document.querySelectorAll(
        '.company-expertise__filter button'
      );
      const lists = document.querySelectorAll('.company-expertise__items');

      const changeButtonState = (i) => {
        for (const button of filterButtons) {
          button.classList.remove('active');
        }

        filterButtons[i].classList.add('active');
      };

      const changeContentState = (i) => {
        for (const list of lists) {
          list.classList.remove('active');
        }
        lists[i].classList.add('active');
      };

      filterButtons.forEach((filter, index) => {
        filter.addEventListener('click', () => {
          changeButtonState(index);
          changeContentState(index);
        });
      });
    }
  }

  function faqAccordion() {
    const careerFaq = document.querySelector('.career-faq');

    if (careerFaq) {
      const cards = document.querySelectorAll('.career-faq__card');
      const cardsList = document.querySelector('.career-faq__cards');

      const removeActiveCards = function() {
        cards.forEach((card) => {
          card.classList.remove('active');
        });
      };

      const checkForActive = function(button) {
        const currentCard = button.closest('.career-faq__card');
        const cardStatus = currentCard.classList.contains('active');
        if (!cardStatus) {
          removeActiveCards();
          currentCard.classList.add('active');
        } else {
          currentCard.classList.remove('active');
        }
      };

      cardsList.addEventListener('click', (evt) => {
        const button = evt.target;

        if (button.classList.contains('career-faq__sub-title') ||
            button.classList.contains('career-faq__close-button') ||
            button.closest('.career-faq__card')) {
          checkForActive(button);
        }
      });
    }
  }

  function feedbackSlider() {
    const feedbackSlider = document.querySelector('.feedback__slider');

    if (feedbackSlider) {
      document.querySelectorAll('.feedback__arrow_left');
      document.querySelectorAll('.feedback__arrow_right');

      // nextButtons.forEach((button) => {
      //   button.addEventListener('click', () => {
      //     setInterval(() => {
      //       const parent = document.querySelector('.swiper-wrapper');
      //       const activeSlide = document.querySelector('.swiper-slide-active');

      //       parent.style.height = `${activeSlide.offsetHeight}px`;

      //     }, 600)
      //   });
      // });

      // prevButtons.forEach((button) => {
      //   button.addEventListener('click', () => {
      //     setInterval(() => {
      //       const parent = document.querySelector('.swiper-wrapper');
      //       const activeSlide = document.querySelector('.swiper-slide-active');

      //       parent.style.height = `${activeSlide.offsetHeight}px`;

      //     }, 600)
      //   });
      // });
    }
  }

  function hiringSwiper() {
    const hiring = document.querySelector('.career-hiring');
    if (hiring) {
      let init = false;
      let swiper;

      const swiperCard = () => {
        if (window.innerWidth <= 768) {
          init = true;
          new Swiper('.career-hiring__swiper', {
            direction: 'horizontal',
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            spaceBetween: 16,
            speed: 800,
            parallax: true,
            touchEventsTarget: 'wrapper',
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          });
        } else if (init) {
          swiper.destroy();
          init = false;
        }
      };

      swiperCard();

      window.addEventListener('resize', swiperCard);
    }
  }

  function industries() {
    // animation on blue box
    const industriesHero = document.querySelector('.industries-hero');
    let workingAreasLinks;
    function disableInactiveLinks() {
      workingAreasLinks = Array.from(document.querySelectorAll('.working-areas__link'));
      workingAreasLinks
        .filter((link) => (
          link.href === `${window.location}#`
        ))
        .forEach((link) => {
          link.addEventListener('click', (e) => (
            e.preventDefault()
          ));
          link.classList.add('pointer-events-none');
        });
    }
    if(industriesHero) {

      const steps = document.querySelectorAll('.industries-hero__animation-item');

      let tlStep = gsap.timeline({
        defaults: {
          duration: 1
        },
        repeat: -1,
        repeatDelay: 0
      });

      steps.forEach((step) => {
        tlStep = tlStep
          .to(step, {top: '20%', opacity: 1, ease: 'power3.out'}, '-=0.5')
          .to(step, {duration: 1, top: '20%'})
          .to(step, {yPercent: 300, opacity: 0, duration: 0.5});
      });
      disableInactiveLinks();
    }

  }

  function mapOffice() {
    const officeMap = document.getElementById('officeMap');
    const officeTips = document.querySelectorAll('.company-map__office-tip');
    const officeBlock = document.querySelector('.company-map__image');

    const revealOfficeTip = (officeId, officeRects, officeMapRects) => {
      officeTips.forEach((officeTip) => {
        if (officeTip.id === officeId) {
          officeTip.classList.add('active');
          officeTip.style.top = `${officeRects.top - officeMapRects.top + officeRects.height + 10}px`;
          const officeTipRects = officeTip.getBoundingClientRect();
          const officeBlockRects = officeBlock.getBoundingClientRect();
          const overflow = (officeRects.left + officeTipRects.width / 2) >= officeBlockRects.width ? (officeRects.left + officeTipRects.width / 2 - officeBlockRects.width + 20) : 0;
          officeTip.style.left = `${officeRects.left - officeMapRects.left - officeTipRects.width / 2 + officeRects.width / 2 - overflow}px`;
        } else {
          officeTip.classList.remove('active');
        }
      });
    };

    if (officeMap) {
      const offices = officeMap.querySelectorAll(
        '.company-map__office'
      );

      const map = document.querySelector('.company-map');

      map.addEventListener('click', (event) => {
        const allNodes = new Set([...officeTips, ...offices]);
        if (!Array.from(allNodes).find((node) => node.contains(event.target))) {
          revealOfficeTip();
        }
      });

      officeTips.forEach((officeTip) => officeTip.querySelector('svg').addEventListener('click', () => {
        revealOfficeTip('');
      }));

      offices.forEach((office) => {
        office.addEventListener('click', () => {
          revealOfficeTip(office.id.replace('office-', ''), office.getBoundingClientRect(), officeMap.getBoundingClientRect());
        });
      });
    }
  }

  function parallax() {
    const parallaxWrapper = document.querySelector('.js-parallax');

    if (parallaxWrapper) {
      const parallaxImg = function() {
        Array.from(parallaxWrapper.querySelectorAll('.case-item-wrapper')).forEach((target) => {
          const targetClientRect = target.getBoundingClientRect();
          const targetPosition = {
            top: window.pageYOffset + targetClientRect.top,
            bottom: window.pageYOffset + targetClientRect.bottom,
          };
          const windowPosition = {
            top: window.pageYOffset,
            bottom: window.pageYOffset + document.documentElement.clientHeight,
          };

          if (targetPosition.bottom > windowPosition.top &&
            targetPosition.top < windowPosition.bottom) {
            target.querySelectorAll('div[data-anim]').forEach((item) => {
              item.classList.add('is-in-view');
            });
            const imgPercent = (targetPosition.bottom - windowPosition.top) * -25 / target.clientHeight;
            const imgElement = target.querySelector('.img-parallax');
            if (imgPercent < 0 && imgPercent > -50) {
              imgElement.style.top = `${imgPercent}%`;
              imgElement.style.transform = `translate(-50%, ${imgPercent}%)`;
            }
          }
        });
      };

      const observer = new MutationObserver(() => {
        parallaxImg();
      });
      observer.observe(parallaxWrapper, {childList: true});

      document.addEventListener('scroll', () => {
        window.requestAnimationFrame(parallaxImg);
      });
      document.addEventListener('DOMContentLoaded', () => {
        window.requestAnimationFrame(parallaxImg);
      });
    }
  }

  function platformsSwiper() {
    const platforms = document.querySelector('.cards-list--grid');
    if (platforms) {
      let init = false;
      let swiper;

      const swiperCard = () => {
        if (window.innerWidth <= 768) {
          init = true;
          new Swiper('.cards-list__swiper', {
            direction: 'horizontal',
            slidesPerView: 1,
            centeredSlides: true,
            autoHeight: true,
            spaceBetween: 32,
            speed: 800,
            parallax: true,
            touchEventsTarget: 'wrapper',
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          });
        } else if (init) {
          swiper.destroy();
          init = false;
        }
      };

      swiperCard();

      window.addEventListener('resize', swiperCard);
    }
  }

  function quizForm() {
    const quizSection = document.querySelector('.quiz-form');

    if (quizSection) {
      const checkboxes = quizSection.querySelectorAll('.ui-checkbox input');
      const radioButtons = quizSection.querySelectorAll('.ui-radio-button input');

      const turnOnTextarea = (element) => {
        element.closest('.quiz-form__user-option').querySelector('.quiz-form__textarea').removeAttribute('disabled');
        element.closest('.quiz-form__user-option').querySelector('.quiz-form__textarea').focus();
        element.closest('.quiz-form__user-option').querySelector('.quiz-form__textarea').value = '';
      };

      const turnOffTextarea = (element) => {
        element.closest('.quiz-form__fieldset').querySelector('.quiz-form__textarea').setAttribute('disabled', '');
        element.closest('.quiz-form__fieldset').querySelector('.quiz-form__textarea').value = '';
      };

      // Event Listeners

      radioButtons.forEach((button) => {
        button.addEventListener('change', () => {
          if (button.closest('.quiz-form__user-option')) {
            turnOnTextarea(button);
          } else {
            turnOffTextarea(button);
          }
        });
      });

      checkboxes.forEach((checkbox) => {
        checkbox.addEventListener('change', () => {
          const checkboxesFieldset = checkbox.closest('.quiz-form__fieldset');
          const checkboxOption = checkboxesFieldset.querySelector('.quiz-form__user-option');

          if (checkboxOption) {
            const checkboxesFamily = checkboxesFieldset.querySelectorAll('.ui-checkbox input');

            if (checkbox.closest('.quiz-form__user-option')) {
              if (checkbox.checked) {
                turnOnTextarea(checkbox);

                for (let i = 0; i < checkboxesFamily.length - 1; i++) {
                  checkboxesFamily[i].checked = false;
                }
              } else {
                turnOffTextarea(checkbox);
              }
            } else {
              if (checkbox.checked) {
                checkboxesFamily[checkboxesFamily.length - 1].checked = false;
              }
            }
          }
        });
      });

      if (quizSection.classList.contains('quiz-form--short')) {
        const stepsList = document.querySelectorAll('.quiz-form__step');
        stepsList[0].classList.add('active');
      }

      if (quizSection.classList.contains('quiz-form--full')) {
        const stepBar = document.querySelector('.quiz-form__step-bar');
        const stepsList = document.querySelectorAll('.quiz-form__step');
        const currentStepField = document.querySelector('.quiz-form__current-step');
        const form = document.querySelector('.quiz-form__form');

        const prevButton = quizSection.querySelector('.ui-button--back');
        const nextButton = quizSection.querySelector('.ui-button--next');

        const textareas = document.querySelectorAll('.quiz-form__user-option .quiz-form__textarea');
        const progressBarValue = Math.round(100 / (stepsList.length - 1));
        let currentSlide = form.dataset.stepCounter;

        // Utils

        const hideElements = (...elements) => {
          elements.forEach((element) => {
            element.style.display = 'none';
          });
        };

        const showElements = (...elements) => {
          elements.forEach((element) => {
            element.style.display = 'block';
          });
        };

        // Init functionality

        const initStepCounter = () => {
          stepsList.forEach((step, idx) => {
            if (step.classList.contains('active')) {
              currentStepField.textContent = idx + 1;
              currentSlide = idx + 1;
            }
          });
        };

        const setControls = () => {
          const privacyLink = document.querySelector('.quiz-form__privacy');
          const submitButton = quizSection.querySelector('.ui-button--submit');
          const counter = document.querySelector('.quiz-form__step-counter');

          if (Number(currentSlide) === 1) {
            hideElements(privacyLink, prevButton, submitButton);
            showElements(counter);
          } else if (currentSlide === stepsList.length) {
            hideElements(nextButton, counter);
            showElements(privacyLink, prevButton, submitButton);
          } else {
            hideElements(privacyLink, submitButton);
            showElements(prevButton, nextButton, counter);
          }
        };

        // Update functionality

        const updateCounter = () => {
          currentStepField.textContent = currentSlide;
          stepBar.style.width = `${progressBarValue * currentSlide}%`;
        };

        const resetActiveSteps = () => {
          stepsList.forEach((step) => {
            step.classList.remove('active');
          });
        };

        const setActiveStep = () => {
          stepsList.forEach(() => {
            stepsList[currentSlide - 1].classList.add('active');
          });
        };

        const changeCurrentStep = (direction) => {
          if (direction === 'prev' && currentSlide > 0) {
            resetActiveSteps();

            currentSlide--;

            setActiveStep();
            setControls();
            updateCounter();
          } else if (direction === 'next' && currentSlide < stepsList.length) {
            resetActiveSteps();

            currentSlide++;

            setActiveStep();
            setControls();
            updateCounter();
          }
        };

        // Event Listeners

        prevButton.addEventListener('click', () => {
          changeCurrentStep('prev');
        });

        nextButton.addEventListener('click', () => {
          changeCurrentStep('next');
        });

        window.addEventListener('load', () => {
          initStepCounter();
          setControls();

          stepBar.style.width = `${progressBarValue * currentSlide}%`;

          textareas.forEach((textarea) => {
            textarea.setAttribute('disabled', '');
          });

          stepsList[currentSlide - 1].classList.add('active');
        });
      }
    }
  }

  function requestForm() {
    const body = document.querySelector('body');
    const requestForm = document.querySelector('.request-form');

    const isPresentation = body.classList.contains('presentation-page');

    if (requestForm && !isPresentation) {
      const counterItems = document.querySelectorAll(
        '.request-form__developers-counter'
      );
      const counterButtonsNext = document.querySelectorAll(
        '.request-form__counter--next'
      );

      counterButtonsNext.forEach((button) => {
        button.classList.add('active');
      });

      counterItems.forEach((counterItem) => {
        const counterButtons = counterItem.querySelectorAll(
          '.request-form__counter'
        );
        const counterButtonBack = counterItem.querySelector(
          '.request-form__counter--back'
        );
        const counterOutput = counterItem.querySelector(
          'input.request-form__counter-output'
        );
        let counter = null;

        counterButtons.forEach((button) => {
          button.addEventListener('click', () => {
            if (button.classList.contains('request-form__counter--back')) {
              counter = Number(counterOutput.value);
              if (counter > 0) {
                counter -= 1;
                counterOutput.value = counter;
              }
            } else if (button.classList.contains('request-form__counter--next')) {
              counter = Number(counterOutput.value);
              counter += 1;
              counterOutput.value = counter;
            }
            if (counter >= 1) {
              counterButtonBack.classList.add('active');
              counterItem.previousElementSibling.classList.add('active');
            } else {
              counterButtonBack.classList.remove('active');
              counterItem.previousElementSibling.classList.remove('active');
            }
          });
        });
      });

      // Textarea height

      const TEXTAREA_HEIGHT = 54;

      const autoGrow = function (element) {
        element.style.height = `${element.scrollHeight}px`;
      };

      const textarea = document.querySelector('textarea[name="comment"]');
      if (textarea) {
        textarea.addEventListener('input', function () {
          if (textarea.value.length === 0) {
            textarea.style.height = `${TEXTAREA_HEIGHT}px`;
          } else {
            autoGrow(this);
          }
        });

        textarea.addEventListener('blur', function () {
          autoGrow(this);
        });
      }

      // Validation

      const inputs = document.querySelectorAll('input.request-form__input');

      const showError = function (input) {
        if (
          input.getAttribute('type') === 'text' ||
          input.getAttribute('type') === 'email' ||
          input.classList.contains('request-form__input')
        ) {
          input.classList.add('form__input--is-error');
        }
        if (input.value.length === 0) {
          input.classList.add('form__input--is-error');
        } else if (input.getAttribute('type') !== 'text') {
          input.classList.add('form__input--is-valid');
        }
      };

      const hideError = function (input) {
        input.classList.remove('form__input--is-valid');
        input.classList.remove('form__input--is-error');
      };

      inputs.forEach((input) => {
        if (
          input.getAttribute('name') !== 'name' ||
          input.getAttribute('name') !== 'company' ||
          input.getAttribute('name') !== 'email'
        ) {
          input.addEventListener('blur', () => {
            if (input.checkValidity() === false) {
              showError(input);
            }
          });

          input.addEventListener('input', () => {
            if (
              input.classList.contains('form__input--is-error') ||
              input.classList.contains('form__input--is-valid')
            ) {
              hideError(input);
            }
          });
        }
      });

      const submitButton = document.querySelector('.request-form__submit-btn');

      submitButton.addEventListener('click', () => {
        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          if (
            input.getAttribute('name') !== 'name' ||
            input.getAttribute('name') !== 'company' ||
            input.getAttribute('name') !== 'email'
          ) {
            if (input.checkValidity() === false) {
              showError(input);
            }
          }
        }
      });

      const form = document.querySelector('.request-form__form');

      form.onsubmit = function () {
        autoGrow(textarea);
      };

      // Options menu:

      const optionsOpenBlock = document.querySelector(
        '.request-form__select-title'
      );
      const optionsOpenBtn = document.querySelector('.request-form__menu-btn');
      const heroScrollBtn = document.querySelector('.hero__quote-button');
      const optionsContainer = document.querySelector('.request-form__options');

      const optionsMenuOpen = function () {
        optionsOpenBtn.classList.toggle('active');
        optionsContainer.classList.toggle('active');
      };

      optionsOpenBlock.addEventListener('click', () => {
        optionsMenuOpen();
      });

      if (heroScrollBtn) {
        heroScrollBtn.addEventListener('click', () => {
          if (!optionsContainer.classList.contains('active')) {
            optionsMenuOpen();
          }
        });
      }
    }
  }

  function requestFormPresentation() {
    const requestForm = document.querySelector(
      '.presentation-page .request-form'
    );

    if (requestForm) {
      const submitButton = document.querySelector('.request-form__submit-btn');
      const inputs = document.querySelectorAll('input.request-form__input');
      const fullNameInput = document.querySelector(
        '.request-form__input[name="fullname"]'
      );
      const contactInput = document.querySelector(
        '.request-form__input[name="contact"]'
      );

      const showError = function (input) {
        if (input.getAttribute('type') === 'text') {
          input.classList.add('form__input--is-error');
        }
        if (input.value.length === 0) {
          input.classList.add('form__input--is-error');
        } else if (input.getAttribute('type') !== 'text') {
          input.classList.add('form__input--is-valid');
        }
      };

      const hideError = function (input) {
        input.classList.remove('form__input--is-valid');
        input.classList.remove('form__input--is-error');
      };

      const validateEmail = function (value) {
        const VALID_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (!value.match(VALID_FORMAT)) {
          showError(contactInput);
          return false;
        }

        isContactValid = true;
      };

      const validatePhone = function (value) {
        const VALID_SYMBOLS = '0123456789+-(). ';

        for (const symbol of value) {
          if (!VALID_SYMBOLS.includes(symbol)) {
            showError(contactInput);
            return false;
          }
        }

        isContactValid = true;
      };

      const validateTelegram = function (value) {
        const VALID_LETTERS = 'abcdefghijklmnopqrstuvwxyz';
        const VALID_SYMBOLS = '0123456789_@';

        for (const symbol of value) {
          if (
            !VALID_LETTERS.includes(symbol.toLowerCase()) &&
            !VALID_SYMBOLS.includes(symbol)
          ) {
            showError(contactInput);
            return false;
          }
        }

        isContactValid = true;
      };

      const validateFullnameInput = function (e) {
        const inputValue = e.target.value;
        const inputWords = inputValue.split(' ');
        const VALID_SYMBOLS = /^[а-яА-ЯёЁa-zA-Z]+$/;

        if (inputWords.length > 3 || inputWords.length < 2) {
          showError(fullNameInput);
          return false;
        }

        inputWords.forEach((word) => {
          if (!word.match(VALID_SYMBOLS)) {
            showError(fullNameInput);
            return false;
          }
        });
      };

      const validateContactInput = function (e) {
        const inputValue = e.target.value;
        const isEmail = inputValue.split('@').length === 2;
        const firstSymbol = inputValue[0];

        if (isEmail && firstSymbol !== '@') {
          validateEmail(inputValue);
        } else {
          const VALID_NUMBERS = '0123456789';
          const VALID_TELEGRAM_SYMBOLS = 'abcdefghijklmnopqrstuvwxyz';

          if (
            firstSymbol === '+' ||
            VALID_NUMBERS.includes(firstSymbol) ||
            firstSymbol === '('
          ) {
            validatePhone(inputValue);
          } else if (
            firstSymbol === '@' ||
            VALID_TELEGRAM_SYMBOLS.includes(firstSymbol)
          ) {
            validateTelegram(inputValue);
          } else {
            showError(contactInput);
            return false;
          }
        }
      };

      inputs.forEach((input) => {
        if (
          input.getAttribute('name') !== 'fullname' ||
          input.getAttribute('name') !== 'contact'
        ) {
          input.addEventListener('blur', () => {
            if (input.checkValidity() === false) {
              showError(input);
            }
          });

          input.addEventListener('input', () => {
            if (
              input.classList.contains('form__input--is-error') ||
              input.classList.contains('form__input--is-valid')
            ) {
              hideError(input);
            }
          });
        }
      });

      inputs.forEach((input) => {
        input.setAttribute('autocomplete', 'disabled');
      });

      fullNameInput.addEventListener('input', (e) => validateFullnameInput(e));
      contactInput.addEventListener('input', (e) => validateContactInput(e));

      submitButton.addEventListener('click', (e) => {
        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          if (
            input.getAttribute('name') !== 'fullname' ||
            input.getAttribute('name') !== 'contact'
          ) {
            if (input.checkValidity() === false) {
              showError(input);
            }
          }
        }
      });
    }
  }

  function similarVacanciesCarousel() {
    const similarVacancies = document.querySelector('.similar-vacancies');

    if (similarVacancies) {
      const vacanciesSlides = document.querySelectorAll('.vacancies-detail__item');
      const buttonNext = similarVacancies.querySelector('.swiper-button-next');
      const buttonPrev = similarVacancies.querySelector('.swiper-button-prev');

      if (vacanciesSlides.length < 4) {
        buttonNext.style.visibility = 'hidden';
        buttonPrev.style.visibility = 'hidden';
      } else {
        buttonNext.style.visibility = 'visible';
        buttonPrev.style.visibility = 'visible';
      }
    }
  }

  function whySenla() {
    const whySenla = document.querySelector('.why-senla');

    if (whySenla) {
      // Range
      const slider = document.querySelector('.why-senla__range-input');
      const button = document.querySelector('.why-senla__range-button');
      const selectValue = document.querySelector('.why-senla__range-value');
      const progressBar = document.querySelector('.why-senla__progress-bar');

      selectValue.innerHTML = slider.value;

      const customSlider = function() {
        const maxValue = slider.getAttribute('max');
        const minValue = slider.getAttribute('min');
        const value = `${(slider.value - minValue) / (maxValue - minValue) * 100}%`;

        selectValue.innerHTML = slider.value;
        button.style.left = value;
        progressBar.style.width = value;
      };

      slider.oninput = function() {
        customSlider();
      };

      // radio buttons

      const techButtons = document.querySelectorAll('.why-senla__radio-option--tech');
      const locationButtons = document.querySelectorAll('.why-senla__radio-option--location');
      const techOptions = document.querySelector('.why-senla__tech-options');
      const locationOptions = document.querySelector('.why-senla__location-options');

      const addComaToNumber = function(string) {
        const number = +string.slice(1);

        if (number > 9999 && number < 99999) {
          const firstPart = string.slice(0, 3);
          const secondPart = string.slice(3);
          string = `${firstPart},${secondPart}`;
          return string;
        } if (number > 99999 && number < 999999) {
          const firstPart = string.slice(0, 4);
          const secondPart = string.slice(4);
          string = `${firstPart},${secondPart}`;
          return string;
        } if (number > 999999) {
          const firstPart = string.slice(0, 2);
          const secondPart = string.slice(2, 5);
          const thirdPart = string.slice(5);
          string = `${firstPart},${secondPart},${thirdPart}`;
          return string;
        }
      };

      const resetButtons = function(buttonsList) {
        buttonsList.forEach((currentButton) => {
          const input = currentButton.querySelector('input');
          currentButton.classList.remove('active');
          input.removeAttribute('checked');
        });
      };

      const setActiveButton = function(activeButton) {
        const input = activeButton.querySelector('input');
        activeButton.classList.add('active');
        input.setAttribute('checked', '');
      };

      // Formulas calculation

      const setProcentageValue = function(location) {
        const procentageOutput = document.querySelector('.why-senla__procentage-value');
        let procentageValue;
        switch (location) {
          case 'europe':
            procentageValue = '38%';
            break;
          case 'north-america':
            procentageValue = '55%';
            break;
          case 'united-kingdom':
            procentageValue = '46%';
            break;
          case 'israel':
            procentageValue = '42%';
        }

        procentageOutput.textContent = procentageValue;
      };

      const setTalentPrice = function(location, experts) {
        const talentPriceOutput = document.querySelector('.why-senla__content-price[data-talent]');
        let talentValue;

        switch (location) {
          case 'europe':
            talentValue = `€${10232 * experts}`;
            break;
          case 'north-america':
            talentValue = `$${15300 * experts}`;
            break;
          case 'united-kingdom':
            talentValue = `£${9930 * experts}`;
            break;
          case 'israel':
            talentValue = `₪${38898 * experts}`;
        }

        talentValue = addComaToNumber(talentValue);

        talentPriceOutput.textContent = talentValue;
      };

      const setAdministrativePrice = function(location, experts) {
        const administrativePriceOutput = document.querySelector('.why-senla__content-price[data-administrative]');
        let administrativeValue;

        switch (location) {
          case 'europe':
            administrativeValue = `€${5416 * experts}`;
            break;
          case 'north-america':
            administrativeValue = `$${8100 * experts}`;
            break;
          case 'united-kingdom':
            administrativeValue = `£${6750 * experts}`;
            break;
          case 'israel':
            administrativeValue = `₪${20593 * experts}`;
        }

        administrativeValue = addComaToNumber(administrativeValue);

        administrativePriceOutput.textContent = administrativeValue;
      };

      techOptions.addEventListener('click', (evt) => {
        const currentOption = evt.target;
        const parentOption = currentOption.closest('.why-senla__radio-option');

        if (currentOption.classList.contains('why-senla__radio-option')) {
          resetButtons(techButtons);
          setActiveButton(currentOption);
        } else if (parentOption) {
          resetButtons(techButtons);
          setActiveButton(parentOption);
        }
      });

      locationOptions.addEventListener('click', (evt) => {
        let locationInput;
        let locationValue;
        const sliderValue = slider.value;
        const currentOption = evt.target;
        const parentOption = currentOption.closest('.why-senla__radio-option');

        if (currentOption.classList.contains('why-senla__radio-option')) {
          locationInput = currentOption.querySelector('input');
          locationValue = locationInput.value;

          resetButtons(locationButtons);
          setActiveButton(currentOption);
          setProcentageValue(locationValue);

          if (locationInput.hasAttribute('checked')) {
            setTalentPrice(locationValue, sliderValue);
            setAdministrativePrice(locationValue, sliderValue);
          }
        } else if (parentOption) {
          locationInput = parentOption.querySelector('input');
          locationValue = locationInput.value;

          resetButtons(locationButtons);
          setActiveButton(parentOption);
          setProcentageValue(locationValue);

          if (locationInput.hasAttribute('checked')) {
            setTalentPrice(locationValue, sliderValue);
            setAdministrativePrice(locationValue, sliderValue);
          }
        }
      });

      slider.addEventListener('change', () => {
        const sliderValue = slider.value;
        const locationInputs = document.querySelectorAll('.why-senla__radio-option--location input');

        locationInputs.forEach((input) => {
          if (input.hasAttribute('checked')) {
            const locationValue = input.value;
            setTalentPrice(locationValue, sliderValue);
            setAdministrativePrice(locationValue, sliderValue);
          }
        });
      });

      document.addEventListener('DOMContentLoaded', () => {
        locationButtons.forEach((locationButton) => {
          const locationInput = locationButton.querySelector('input');
          const locationValue = locationInput.value;
          const sliderValue = slider.value;

          if (locationInput.hasAttribute('checked')) {
            setTalentPrice(locationValue, sliderValue);
            setAdministrativePrice(locationValue, sliderValue);
            setProcentageValue(locationValue);
          }
        });
      });
    }
  }

  cookiesAction();
  aboutAccordion();
  anchorScroll();
  articleNav();
  articleWhySenla();
  articlesFilter();
  backButtonOnFooter();
  careerFilter();
  careerForm();
  caseBook();
  caseFilter();
  coursesAccordion();
  developmentProcess();
  expertiseTabs();
  faqAccordion();
  feedbackSlider();
  hiringSwiper();
  industries();
  mapOffice();
  parallax();
  platformsSwiper();
  quizForm();
  requestForm();
  requestFormPresentation();
  similarVacanciesCarousel();
  whySenla();

})();
